import Grid from "@mui/material/Grid";
import VatesCognitaPart from "./VatesCognitaPart";
import KaryotypingPart from "./KaryotypingPart";
import Grow from "@mui/material/Grow";
import CompanyInfo from "./CompanyInfo";

export default function ServicesPage() {
  return (
    <Grid container spacing={3}>
      <Grow in={true} style={{ transformOrigin: 'right top' }}>
        <Grid item md={6} sm={12}>
          <KaryotypingPart/>
        </Grid>
      </Grow>
      <Grow in={true} style={{ transformOrigin: 'left top' }}>
        <Grid item md={6} sm={12}>
          <VatesCognitaPart/>
        </Grid>
      </Grow>
      <Grid item md={12} sm={12}>
          <CompanyInfo />
      </Grid>
    </Grid>
  );
}
