import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Carousel from "react-material-ui-carousel";

import iscn from "../fig/iscn.png";
import del from "../fig/del.png";
import karyo from "../fig/karyo.png";
import micros from "../fig/micros.png";
import pairs from "../fig/pairs.png";
import vates from "../fig/vates.png";
import integration from "../fig/integration.png";
import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from '@mui/icons-material/Launch';

export default function VatesCognitaPart() {
  return (
    <Card>
      <CardActionArea
        component="a"
        href={process.env.REACT_APP_VATES_URL || "/"}
        target="_blank"
      >
        <CardHeader title="VATES: our cognitive platform" action={
          <IconButton color="primary" size="large" disableRipple>
            <LaunchIcon color="primary"/>
          </IconButton>
        }/>
      </CardActionArea>
      <CardContent>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <img src={vates} alt="Vates Logo" style={{ width: '100%', height: 'auto' }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ marginTop: 2 }}>
        Welcome to <strong>VatesCognita</strong>, the pinnacle of innovation in the field of Large Language Models. At VedMed, our commitment lies in harnessing the power of advanced AI to meet your needs in unprecedented ways.
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: 4 }}>
        The VatesCognita Platform introduces a suite of pioneering services aimed at transforming laboratory experiences, fostering creativity, and simplifying intricate processes.
        </Typography>

        <Typography variant="h5" gutterBottom>
        Chat with our AIs
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
        Our AIs are highly specilized and can clarify a wide rage of topics.
        </Typography>
        <Carousel>
          {
            [
              {src: del, alt: "DDG del"},
              {src: iscn, alt: "ISCN question"},
              {src: karyo, alt: "Karyo"},
            ].map((p, i) => <CarouselItem key={i} {...p} />)
          }
        </Carousel>

        <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
        </Typography>

        <Typography variant="h5" gutterBottom>
        Customized to your needs
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
        Our solutions are specifically tailored for cytogenetic applications, and the artificial intelligence models that power them are adept at simulating realistic micrographs, individual chromosomes, pairs of chromosomes, and complete karyotypes by specifying ISCN definitions.
        </Typography>
        <Carousel>
          {
            [
              {src: micros, alt: "Micrographies"},
              {src: pairs, alt: "Chromosome pairs"},
            ].map((p, i) => <CarouselItem key={i} {...p} />)
          }
        </Carousel>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
        These models comprehend the ISCN cytogenetic standard, and are capable of being customized with the data from your laboratory, ensuring the generated images closely resemble those produced by your lab equipment to depict a wide array of genetic conditions.
        </Typography>
        <Typography variant="h5" gutterBottom>
        Connect our AIs to your Lab
        </Typography>
        We are specialists in integrating with your systems, offering AI-powered ChatBots that can process the data in your systems in real-time to answer questions.
        <Typography variant="body1" gutterBottom>

        </Typography>
        <Carousel>
          {
            [
              {src: integration, alt: "Vates Integration"},
            ].map((p, i) => <CarouselItem key={i} {...p} />)
          }
        </Carousel>
      </CardContent>
    </Card>
  )
}

interface CarouselItemProps {
  src: string;
  alt: string;
}

function CarouselItem({ src, alt }: CarouselItemProps) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', aspectRatio: '1' }}>
      <img src={src} alt={alt} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} />
    </Box>
  );
}
