import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import Link from "@mui/material/Link";

export default function PrivacyPolicyPage() {
  return (
    <Stack gap={2}>
      <Grow in={true} style={{ transformOrigin: 'center top' }}>
        <Card>
          <CardHeader title="Privacy Policy"/>
          <CardContent>
            <Typography variant="h6" gutterBottom pt={3}>
              Data collected on the basis of consent
            </Typography>
            <Typography variant="body1" gutterBottom>
              Upon your request and expression of consent, we collect the following data for the purpose of providing services to you. Your data is not used for any other purposes or shared with third parties. It is removed upon your withdrawal of consent or your request to terminate theses services.
            </Typography>
            <Typography variant="body1" gutterBottom>
              <b>Name, email address, phone number:</b> this data is collected when you contact us in email or phone.
            </Typography>
            <Typography variant="body1" gutterBottom>
              <b>IP and browser user agent string:</b> this data is collected when you leave a comment.
            </Typography>
            <Typography variant="body1" gutterBottom>
              <b>Retention period:</b> the aforementioned data is retained indefinitely so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.
            </Typography>

            <Typography variant="h6" gutterBottom pt={3}>
              Data collected on the basis of legitimate interest
            </Typography>
            <Typography variant="body1" gutterBottom>
              Based on our legitimate interests, we collect the following data for the purpose of running this website. Your data is not used for any other purposes or shared with third parties. It is removed upon your request.
            </Typography>

            <Typography variant="h6" gutterBottom pt={3}>
              Statistics
            </Typography>
            <Typography variant="body1" gutterBottom>
              The website uses a minimal build of Google Analytics, a service which transmits website traffic data to Google servers in the United States and allows us to notice trends to improve the user experience on our website. This minimal build processes personal data such as: the unique User ID set by Google Analytics, the date and time, the title of the page being viewed, the URL of the page being viewed, the URL of the page that was viewed prior to the current page, the screen resolution, the time in local timezone, the files that were clicked on and downloaded, the links clicked on to an outside domain, the type of device, and the country, region, and city.
            </Typography>
            <Typography variant="body1" gutterBottom>
            You may opt out of this tracking at any time by activating the “Do Not Track” setting in your browser.
            Embedded content from other websites
            Embedded content from other websites behaves in the exact same way as if the visitor had visited the other website. We use the third-party service <b>Calendly</b> to implement Book a Demo functionality. The Privacy Notice of Calendly is available <Link href="https://calendly.com/legal/privacy-notice" target="_blank">here</Link>.
            This information is also displayed when you click Book a Demo.
            These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracing your interaction with the embedded content if you have an account and are logged in to that website.
            </Typography>

            <Typography variant="h6" gutterBottom pt={3}>
            Your rights pertaining your data
            </Typography>
            <Typography variant="body1" gutterBottom>
            If you have left comments on the Website, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we rectify or erase any personal data we hold about you. Please send your request to <Link href="mailto:vedmedinfo@gmail.com">vedmedinfo@gmail.com</Link>.
            <ul>
              <li>The right to withdraw consent</li>
              <li>The right of access</li>
              <li>The right to erasure</li>
              <li>The right to rectification</li>
              <li>The right to data portability</li>
              <li>The right to object</li>
              <li>Notification of data breaches</li>
              <li>The right to lodge a complaint with a supervisory authority</li>
            </ul>
            </Typography>
          </CardContent>
        </Card>
      </Grow>
    </Stack>
  );
}
