import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import { deepOrange, grey, teal } from '@mui/material/colors';
import PageFrame from './components/PageFrame';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import CompanyPage from './components/CompanyPage';
import ServicesPage from './components/ServicesPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';

const theme = createTheme({
  palette: {
    primary: teal,
    secondary: deepOrange,
    text: {
      primary: grey[800]
    },
    background: {
      default: teal[50],
    },
  },
  typography: {
    fontFamily: [
      'Work Sans Variable',
      'sans-serif',
    ].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <PageFrame>
          <Routes>
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/company" element={<CompanyPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route
              path="*"
              element={<Navigate to="/services" replace />}
            />
          </Routes>
        </PageFrame>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
