import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

export default function CompanyInfo() {
  const theme = useTheme();
  return (
    <Card sx={{ backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>
      <CardContent sx={{ m: 0 }}>
        <Grid container>
          <Grid item sm={6}>
            <List dense>
              <ListItem>
                <ListItemText>Copyright VedMed 2024</ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={6}>
            <List dense>
              <ListItem>
                <ListItemButton component={Link} to={"/privacy-policy"}>
                  <ListItemText>Privacy Policy</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}