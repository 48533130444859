import React, { ReactElement, useState } from "react";

import logo from '../fig/logo.png'
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Fab from "@mui/material/Fab";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from "@mui/material/Fade";
import { cloneElement } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ListItemIcon from "@mui/material/ListItemIcon";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { PopupModal } from "react-calendly";

interface Props {
  children: React.ReactElement;
}

// function HideOnScroll({ children }: Props) {
//   const trigger = useScrollTrigger();
//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

function ElevationScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function PageFrame({ children }: Props) {
  const location = useLocation();
  const [calendlyOpen, setCalendlyOpen] = useState(false);

  return (
    <>
      <CssBaseline />
      <ElevationScroll>
        <AppBar>
          <Toolbar disableGutters sx={{ gap: 2 }}>

            <IconButton sx={{ p: 0 }} component={Link} to="/services">
              <img src={logo} alt="" height="100px" />
            </IconButton>

            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row">
              <Button
                startIcon={<LinkedInIcon />}
                component="a"
                color="inherit"
                variant="text"
                href="https://www.linkedin.com/company/vedmed"
                target="_blank"
                rel="noopener noreferrer"
                sx={{textTransform: "lowercase"}}
              >
                linkedin.com/company/vedmed
              </Button>
              <CopyButton content="https://www.linkedin.com/company/vedmed" tooltip="Copy LinkedIn URL" />
            </Stack>

            <Stack direction="row">
              <Button
                startIcon={<EmailIcon />}
                component="a"
                color="inherit"
                variant="text"
                href="mailto:vedmedinfo@gmail.com"
                sx={{textTransform: "lowercase"}}
              >
                vedmedinfo@gmail.com
              </Button>
              <CopyButton content="vedmedinfo@gmail.com" tooltip="Copy email address" />
            </Stack>

            <Button
              color="inherit"
              variant="text"
              onClick={() => setCalendlyOpen(true)}
              startIcon={<CalendarMonthIcon />}
            >
              Book a demo
            </Button>
            <PopupModal
              url={process.env.REACT_APP_CALENDLY_URL!}
              rootElement={document.getElementById("root")!}
              open={calendlyOpen}
              onModalClose={() => setCalendlyOpen(false)}
            />
            <Button
              component={Link}
              to="/services"
              color="inherit"
              variant="outlined"
              disabled={location.pathname === "/services"}
            >
              Services
            </Button>
            <Button
              component={Link}
              to="/company"
              color="inherit"
              variant="outlined"
              disabled={location.pathname === "/company"}
            >
              Company
            </Button>
            <Box sx={{ pr: 2 }} />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar id="back-to-top-anchor" />
      <Container>
        <Box sx={{ my: 2, pt: 5, pb: 1 }}>
          {children}
        </Box>
      </Container>
      <ScrollTop>
        <Fab size="medium" aria-label="scroll back to top" color="primary">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}

function ScrollTop(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function BottomAppBar() {
  return (
    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0,  }}>
      <Toolbar sx={{ alignItems: "flex-start", gap: 4 }}>
        <Stack flexGrow={1}>
          <List dense>
            <ListItem>
              <ListItemText>Copyright VedMed 2024</ListItemText>
            </ListItem>
          </List>
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack flexGrow={1}>
          <List dense>
            <ListItem>
              <ListItemButton component={Link} to={"/privacy-policy"}>
                <ListItemText>Privacy Policy</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack flexGrow={1}>
          <List dense>
            <ListItem>
              <ListItemText>Budapest, HU</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Alicante, ES</ListItemText>
            </ListItem>
          </List>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

function ExternalLinkItem({href, text, icon}: {href: string, text: string, icon: ReactElement}) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  )
}

function EmailItem({email} : {email: string}) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(email)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <Tooltip title="Copy address to clipboard">
          <IconButton onClick={handleCopyClick}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemButton
        component="a"
        href={`mailto:${email}`}
      >
        <ListItemIcon><EmailIcon /></ListItemIcon>
        <ListItemText primary={email} />
      </ListItemButton>
      <Snackbar
        open={isCopied}
        autoHideDuration={6000}
        onClose={() => setIsCopied(false)}
        message="Copied to clipboard"
      />
    </ListItem>
  );
}

function CopyButton({content, tooltip}: { content: string; tooltip: string; }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(content)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton onClick={handleCopyClick}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={isCopied}
        autoHideDuration={6000}
        onClose={() => setIsCopied(false)}
        message="Copied to clipboard"
      />
    </>
  );
}
