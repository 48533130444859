import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import jr_face from "../fig/jr.png";
import jalal_face from "../fig/jalal.png";
import repai_face from "../fig/repai.png";
import gyuri_face from "../fig/gyuri.png";
import pinter_face from "../fig/pinter.png";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Collapse from "@mui/material/Collapse";
import { useState } from "react";
import CardActionArea from "@mui/material/CardActionArea";
import CloseIcon from '@mui/icons-material/Close';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CompanyInfo from "./CompanyInfo";

const jr_profile = "Javier is a Computer Science Engineer with passion for Entrepreneurship, Computer Vision, and Generative Technologies. Starting his career journey in software and data engineering, he has evolved into a versatile professional through diverse roles including MLOps Engineer, Computer Vision Specialist, and Chief AI Officer. His expertise spans managing cloud infrastructures, deploying generative models, and leading AI projects across multinationals to startups in sectors like biomedical, industrial, and defense. With an MSc in Computer Science specialized in Image Processing and Computer Vision, Javi’s unique blend of technical skills and broad industry exposure makes him a dynamic force in tech innovation."
const jalal_profile = "Jalal is an accomplished AI researcher with a Ph.D. in Computer Science, specializing in computer vision and image processing. With a track record of scholarly publications, he has made numerouse contributions to the field of AI. Jalal began his career in academia, focusing on in-depth data analysis and algorithm development, which laid a solid foundation for his expertise in AI technologies, making him a sought-after expert in artificial intelligence."
const repai_profile = "Attila is a Computer Science Engineer with industrial experience in a wide range of frontend and backend technologies, as well as image processing. Having started his career in the bank and insurance sector, he values careful design and high code quality standards. Passionate in studying and research, he is in pursuit of state-of-the-art technologies and best practices."
const gyuri_profile = "György Cserey is a distinguished Full Professor and Dean at Pázmány Péter Catholic University (PPCU), specializing in Information Technology and Bionics. His academic journey began with an M.Sc. degree in Electrical Engineering from the University of Technology and Economics, Budapest. György expanded his research horizons as a visiting researcher at Notre Dame University (Indiana, USA) from April 2003 to June 2004, where he contributed to a Cellular Nonlinear Network (CNN) project, focusing on image processing applications in robotics and immunology. He earned his Ph.D. in Infobionics from PPCU in 2006. His research primarily explores the computational dynamics of multicore processing arrays and extends into sensory robotics, parallel computing, machine learning, and neuro-bio-inspired systems. György has guided over 100 students, with 20 advancing to Ph.D. studies under his mentorship. He boasts an impressive record of over 75 publications and 7 patents and has coordinated more than 10 significant academic and R&D projects. Beyond academia, Dr. Cserey is an entrepreneurial force, having founded three spin-off companies. He is also an esteemed member of the European Innovation Champions' Club (SMEI (2015), SMEII (2017))."
const pinter_profile = "Attila is a distinguished founder with deep expertise in AI software engineering and data science, particularly in image analysis. He holds both an engineering degree and an MBA, seamlessly integrating technical innovation with strategic business oversight. Attila has developed key algorithms for biomedical imaging, enhancing precision and functionality, and applies his skills to anomaly detection in system logs and cryptocurrency markets for improved security and transparency. Previously, he managed complex equipment in high-tech labs, gaining valuable insights into scientific workflows and earning a reputation as a thorough auditor of data integrity. Dedicated to advancing the field of artificial intelligence, Attila combines his technical skills, business acumen, and a hands-on approach to research and development, making significant contributions to technology applications in image analysis and anomaly detection.";

export default function CompanyPage() {
  const team = [
    { photo: jr_face, name: "Javier Rico", role: "CTO & Founder", bio: jr_profile },
    { photo: pinter_face, name: "Attila Pintér", role: "CAIO & Founder", bio: pinter_profile },
    { photo: gyuri_face, name: "György Cserey", role: "Strategic Advisor & Founder", bio: gyuri_profile},
    { photo: repai_face, name: "Attila Répai", role: "Full-stack Senior Engineer", bio: repai_profile },
    { photo: jalal_face, name: "Jalal Al-Afandi", role: "Senior AI Engineer", bio: jalal_profile },
  ];
  return (
    <Stack gap={2}>
      <Grow in={true} style={{ transformOrigin: 'center bottom' }}>
        <Card>
          <CardHeader title="Our Mission"/>
          <CardContent>
            <Typography variant="body1" gutterBottom>
            In the intricate and ever-evolving field of biomedical science, particularly within cytogenetics, a revolution is on the horizon, and we are at the forefront. At <strong>VedMed</strong>, we are not just a company; we are a vibrant ecosystem of innovation, dedication, and breakthrough. Established with the vision to integrate cutting-edge artificial intelligence into the realm of laboratory sciences, our journey is marked by relentless pursuit, dynamic solutions, and transformative technologies.
            </Typography>
          </CardContent>
          <CardHeader title="Who We Are"/>
          <CardContent>
            <Typography variant="body1" gutterBottom>
            We are a young, ambitious company, embracing the richness of diversity and the power of unity. Our team is a diverse tapestry of software engineers, deep learning specialists, and customer-facing programme managers, each bringing unique perspectives, unparalleled expertise, and a shared commitment to excellence. At <strong>VedMed</strong>, every team member is a crucial piece of the larger puzzle, working in harmony to drive innovation, foster breakthroughs, and deliver excellence.
            </Typography>
          </CardContent>
          <CardHeader title="Redefining Karyotyping in Cytogenetics"/>
          <CardContent>
            <Typography variant="body1" gutterBottom>
            Karyotyping, a fundamental process in cytogenetics, has found its perfect match in our dedicated team of engineers and researchers. Armed with pioneering AI technologies and an unwavering resolve, we are set to reshape the industry, transcending traditional boundaries and unlocking new potentials. Our first two flagship products are testament to our focus and expertise, specifically designed to revolutionize cytogenetics practices and enhance laboratory efficiencies.
            </Typography>
          </CardContent>
          <CardHeader title="Collaboration and Growth"/>
          <CardContent>
            <Typography variant="body1" gutterBottom>
            Our journey is bolstered by strategic collaborations and partnerships that enrich our capabilities and expand our reach. We are proud to collaborate with a leading <strong>Hungarian Hospital</strong> and to partner with <strong>Pázmány Péter Catholic University</strong>, initiatives that not only enhance our research and development efforts but also bridge academic excellence with industry innovation. With offices nestled in the historic city of Budapest, Hungary, and the vibrant Alicante, Spain, we are strategically positioned to influence, engage, and innovate across borders.
            </Typography>
          </CardContent>
          <CardHeader title="Be Part of the Revolution"/>
          <CardContent>
            <Typography variant="body1" gutterBottom>
            At <strong>VedMed</strong>, we are more than just a company; we are a movement. A movement towards a brighter, smarter, and more efficient future in biomedical science and beyond. As we continue to grow, explore, and innovate, we invite you to join us on this remarkable journey. Whether as part of our diverse team, a collaborative partner, or a valued client, your journey with us is poised to be nothing short of revolutionary.
            </Typography>
          </CardContent>
          <CardHeader title="Acknowledgement"/>
          <CardContent>
            <Typography variant="body1" gutterBottom>
            The implementation of the project named "Artificial Intelligence-Assisted Karyotyping for Medical Diagnostics." was supported by the National Research, Development and Innovation Office with the assistance provided to project number 2021-1.1.4-GYORSÍTÓSÁV-2022-00054.
            </Typography>
          </CardContent>
        </Card>
      </Grow>
      <Grow in={true} style={{ transformOrigin: 'center top' }}>
        <Card>
          <CardHeader title="Our Team"/>
          <CardContent>
            <Grid container spacing={3} justifyContent="center">
              {
                team.map((member, i) => <Grid item md={4} sm={6} xs={12} key={i}>
                  <PersonItem photo={member.photo} name={member.name} role={member.role} bio={member.bio} />
                </Grid>)
              }
            </Grid>
          </CardContent>
        </Card>
      </Grow>
      <CompanyInfo />
    </Stack>
  );
}

interface PersonItemProps {
  photo: string;
  name: string;
  role: string;
  bio?: string;
}

function PersonItem({photo, name, role, bio}: PersonItemProps) {
  const [showBio, setShowBio] = useState(false);

  return <Card>
    <Collapse in={!showBio}>
      <CardActionArea onClick={() => setShowBio(true)} disabled={!bio}>
        <CardMedia component="img" src={photo} sx={{ aspectRatio: 1 }}/>
        <CardHeader title={name} subheader={role} action={bio && <FingerprintIcon color="primary" />} />
      </CardActionArea>
    </Collapse>
    <Collapse in={showBio}>
      <CardActionArea onClick={() => setShowBio(false)}>
        <CardHeader title={name} subheader={role} action={<CloseIcon color="primary" />} />
        <CardContent sx={{ aspectRatio: 1, overflowY: "scroll" }}>
          <Typography variant="body1" color="text.secondary" className="bio">
            {bio}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Collapse>
  </Card>;
}
