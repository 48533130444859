import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Carousel from "react-material-ui-carousel";

import Box from "@mui/material/Box";
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from "@mui/material/IconButton";
import CardActionArea from "@mui/material/CardActionArea";

export default function KaryotypingPart() {
  return (
    <Card>
      <CardActionArea
        component="a"
        href={process.env.REACT_APP_KARYOTYPING_URL || "/"}
        target="_blank"
      >
        <CardHeader title="KAYRA: Revolutionizing Karyotyping"
          subheader="Empowering Cytogeneticists with State-of-the-Art Automation"
          action={
          <IconButton color="primary" size="large" disableRipple><LaunchIcon /></IconButton>
        }/>
      </CardActionArea>
      <CardContent>
        {/*<Carousel>
          {
            [
              {src: logo1, alt: "logo 1"},
              {src: logo2, alt: "logo 2"},
            ].map((p, i) => <CarouselItem key={i} {...p} />)
          }
        </Carousel>*/}
        <Typography variant="body1" gutterBottom>
        In the complex field of cytogenetics, precision and reliability are crucial. We are launching a groundbreaking advancement in karyotyping technology.
        Our innovative service uses advanced AI to automate micrograph analysis, providing accurate karyograms directly via your web browser, without the need for software installation or heavy hardware. Experience innovation at your fingertips.</Typography>
        <Typography variant="h5" gutterBottom>
        Why Choose Us?
        </Typography>
          <ol>
            <li><Typography variant="body1" gutterBottom><b>Rapid Analysis:</b> Time is of the essence in genetic analysis.
              Our platform processes each image in seconds, ensuring you get results swiftly without compromising accuracy.</Typography></li>
            <li><Typography variant="body1" gutterBottom><b>Web-Based Accessibility:</b> With just an internet connection, access our platform from anywhere.
              Upload your micrographs and receive detailed kariograms and ISCN codes with unparalleled ease.</Typography></li>
            <li><Typography variant="body1" gutterBottom><b>AI-Driven Precision:</b> Employing state-of-the-art AI for image segmentation and classification,
              our service offers unparalleled accuracy.</Typography></li>
            <li><Typography variant="body1" gutterBottom><b>Unmatched Security:</b> We prioritize data security through encrypted communications, secure login,
              and optional encrypted storage, ensuring your information remains private and accessible only to you.</Typography></li>
            <li><Typography variant="body1" gutterBottom><b>User-Centric Design:</b> Our service is designed for your convenience.
              Store data locally and utilize cloud resources solely for image analysis, ensuring privacy and easy access.</Typography></li>
          </ol>
        <Typography variant="h5" gutterBottom>Tailored for Excellence</Typography>
        <Typography variant="body1" gutterBottom>
        Designed for cytogeneticists, our service streamlines the karyotyping process,
        enabling the analysis of complex chromosomal arrangements and routine screenings with high accuracy and speed.</Typography>
        <Typography variant="h5" gutterBottom>Proven Results</Typography>
        <Typography variant="body1" gutterBottom>
        Join leading cytogenetics laboratories like our partners in Hungary, who have experienced firsthand the benefits of our innovative service.
        Their satisfaction and improved workflow stand testament to our platform's effectiveness and reliability.</Typography>
        <Typography variant="h5" gutterBottom>Flexible Pricing</Typography>
        <Typography variant="body1" gutterBottom>
        Understanding the diverse needs of our clients, we offer customized quotes tailored to your specific cytogenetic analysis requirements.
        Contact us to explore how we can support your needs effectively within your budget.</Typography>
        <Typography variant="h5" gutterBottom>Ready to Transform Your Karyotyping Process?</Typography>
        <Typography variant="body1" gutterBottom>
        Explore the future of cytogenetics with our efficient, accurate, and secure karyotyping service.
        For more about our expert team, visit our dedicated team page.</Typography>
      </CardContent>
    </Card>
  )
}

interface CarouselItemProps {
  src: string;
  alt: string;
}

function CarouselItem({ src, alt, }: CarouselItemProps) {
  return <Box sx={{ aspectRatio: 1 }}>
    <img src={src} alt={alt} width="100%" />
  </Box>
}
